import React from "react";

function HomePage() {
  return (
    <div style={{ padding: "71px 48px" }}>
      <p>Home page is coming soon.</p>
      <p style={{ marginTop: "23px" }}>
        Meantime, explore the components on the left.
      </p>
    </div>
  );
}

export default HomePage;
